import { AnyObject } from 'yup/lib/types';
import { doRequest, managerApiInstance } from 'clients/api';
import { ThemeColors } from 'common/theme';
import { PlainGenericResponse } from '../interfaces/paginated.interface';

interface GeneralSettings extends AnyObject {
    general: {
        hideSignupCitizen: boolean;
        hideSignupProvider: boolean;
        enableHttpOnlyToken: boolean;
        hideSignupOrganization: boolean;
        showChatSupportCitizen: boolean;
        showChatSupportProvider: boolean;
        showChatSuppertPublicArea: boolean;
        showChatSupportOrganization: boolean;
        showFinancialDataSignup: boolean;
        hideSystemHelpCitizen: boolean;
        hideSystemHelpProvider: boolean;
        hideSystemHelpOrganization: boolean;
        hideSystemHelpAdmin: boolean;
        showProductMarketing: boolean;
        versionOfAcceptedTerms: string;
        requiredTecnicalResponsibleToSegment: boolean;
    };
    images: {
        signupUrlImage: string;
        signupUrlLink: string;
        providerUrlImage: string;
        providerUrlLink: string;
    };
    process: {
        allowHideProcess: boolean;
        requiredPersonalDataToDownloadDocuments: boolean;
        enableModule: boolean;
    };
    provider: {
        pendingRegisterRequestMessage: string;
    };
    proposals: {
        allowBidsOnlyFromTheSameSupplySegment: boolean;
    };
    auctionMode: {
        E: {
            includeBidsInChats: boolean;
        };
        L: {
            includeBidsInChats: boolean;
        };
        R: {
            includeBidsInChats: boolean;
        };
    };
    invoices: {
        enableModule: boolean;
    };
    marketplace: {
        enableModule: boolean;
    };
    purchasePlanning: {
        enableModule: boolean;
    };
    priceBase: {
        enableModule: boolean;
    };
    hiringPlans: {
        enableModule: boolean;
    };
    cognito: {
        enableModule: boolean;
        enableGoogleLogin: boolean;
        enableMicrosoftLogin: boolean;
    };
    privacyPolicyNotice: {
        showTextFormat: boolean;
        language: {
            'pt-BR': string;
            en: string;
        };
    };
}

export interface Platform {
    id: string;
    name: string;
    domain: string;
    description: string;
    urlOriginalLogo: string;
    urlNegativeLogo: string;
    urlOriginalIcon: string;
    urlNegativeIcon: string;
    notificationEmail: string;
    primaryColor: string;
    fileSettings: {
        extensions: string[];
        maxUploadSizeOrganization: string;
        maxUploadSizeProvider: string;
    };
    generalSettings: GeneralSettings;
    isDefault: number;
    fromMail: string;
    analyticsCode: string;
    privacyPolicyUrl: string;
    termsOfService: string;
    updatedAt?: Date | null;
    themeColors?: ThemeColors;
    apps?: {
        php: string;
        dispute: string;
        manager: string;
        generalFiles: string;
    };
}

const platformsRequests = {
    getPlatformByOrigin: async () =>
        doRequest<PlainGenericResponse<Platform>>(
            managerApiInstance.post('/platforms/getPlatformByOrigin')
        ),
    getPlatformContext: async () =>
        doRequest<PlainGenericResponse<Platform[]>>(
            managerApiInstance.get('/platforms/getPlatformContext')
        ),
};

export { platformsRequests };
